import React from "react";
import styled from 'styled-components';

// 固定画像のパスを定義
const minoImage1 = require('../../images/mino/mino-1-1.jpg');
const minoImage2 = require('../../images/mino/mino-1-2.jpg');

// スタイルを定義
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Image = styled.img`
  width: 42%;
  height: auto;
  max-width: 500px;
  box-sizing: border-box;
  padding: 8px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const MinoBody1 = () => {
  return (
    <Container>
      <Title>窯元とのつながりを大切に</Title>
      <p>
      美濃焼の産地である岐阜県土岐市に物流拠点を設け、現在100社を超える窯元（産地メーカー）との直接お取引をさせて頂いております。<br/><br/>
      また、年に1回は窯元と当社スタッフとの懇親会を開催し、窯元との親交を深めております。<br/><br/>
      地の利を活かし、窯元とのつながりを大切にすることで、お客様のご要望に迅速にお応えできるよう努めております。<br/>
      </p>
      <ImageContainer>
        <Image src={minoImage1} alt="Mino1-1" />
        <Image src={minoImage2} alt="Mino1-2" />
      </ImageContainer>
    </Container>
  );
};

export default MinoBody1;
