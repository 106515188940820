import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
  listItem: {
    marginBottom: '12px',
    lineHeight: '1.5',
  }
};

const ManagementPhilosophy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>経営理念</h1>
      <ul style={styles.list}>
        <li style={styles.listItem}>1. 豊栄/HOEIは、従業員の生活が豊かに栄えることを目的とし、存在します</li>
        <li style={styles.listItem}>2. その目的は、消費者が豊かになり、生産者が栄える活動によって達成されます</li>
        <li style={styles.listItem}>3. その活動は、明るく楽しく元気よく行われます</li>
      </ul>
    </div>
  );
};

export default ManagementPhilosophy;