import React from "react";
import businessTopImage from '../../images/hoei-hp/business-top.jpg';

const BusinessTopImage = () => {
  const styles = {
    headerSection: {
      marginBottom: '8px',
    },
    headerContainer: {
      width: '100%',
      height: '80vh',
      marginBottom: '40px',
    },
    headerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    textContent: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0 20px',
      textAlign: 'center',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
    }
  };

  return (
    <div>
      <section style={styles.headerSection}>
        <div style={styles.headerContainer}>
          <img 
            src={businessTopImage} 
            alt="Business Top" 
            style={styles.headerImage} 
          />
        </div>
        <div style={styles.textContent}>
          <h2 style={styles.title}>豊栄/HOEIについて</h2>
        </div>
      </section>
    </div>
  );
};

export default BusinessTopImage;