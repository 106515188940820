import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

const StyledNavLink = styled.a`
  ${tw`fixed p-4 bg-[#110b41] text-white border border-white rounded-full cursor-pointer z-50`}
  width: 212px;
  transition: opacity 0.5s ease, font-weight 0.5s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
  
  // デバイスタイプに応じたポジショニング
  bottom: ${({ deviceType, isBannerVisible }) =>
    deviceType === 'mobile' ? '40px' : isBannerVisible ? '100px' : '100px'};
  right: ${({ deviceType }) => (deviceType === 'pc' ? '40px' : '50%')};
  transform: ${({ deviceType }) => (deviceType !== 'pc' ? 'translateX(50%)' : 'none')};
  text-decoration: none;
  text-align: center;

  &:hover {
    background-color: #0d0730;
    font-weight: bold;
    text-decoration: none;
  }
`;

const ContactButton = ({ isBannerVisible }) => {
  const [visible, setVisible] = useState(false);
  const [deviceType, setDeviceType] = useState('pc');

  useEffect(() => {
    const handleScroll = () => {
      setVisible(window.scrollY > 100);
    };

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDeviceType('mobile');
      } else if (window.innerWidth <= 1023) {
        setDeviceType('tablet');
      } else {
        setDeviceType('pc');
      }
    };

    // 初期表示のデバイスタイプを設定
    handleResize();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <StyledNavLink
      href="/Contact-us"
      target="_blank"
      rel="noopener noreferrer"
      isVisible={visible}
      deviceType={deviceType}
      isBannerVisible={isBannerVisible}
    >
      お問い合わせはこちら
    </StyledNavLink>
  );
};

export default ContactButton;
