import React, { useState } from 'react';
import MessageFromMember1 from './messageFromMember1';
import MessageFromMember2 from './messageFromMember2';
import reqruitMember1TopImage from '../../images/reqruit/member1-top.jpg';
import reqruitMember2TopImage from '../../images/reqruit/member2-top.jpg';

const styles = {
  heading: {
    fontSize: '28px',
    fontWeight: 'bold',
    margin: '64px 0px 24px 0px',
    backgroundColor: '#110b41ff',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
  },
  memberTabs: {
    textAlign: 'left',
    margin: '20px',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    marginBottom: '20px',
    flexDirection: 'row', // デフォルトは横並び
  },
  tabButton: {
    width: '320px', // デフォルトのボタン幅
    padding: '10px',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    borderRadius: '5px',
    transition: 'background-color 0.3s, color 0.3s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabButtonActive: {
    backgroundColor: '#110b41ff',
    color: '#fff',
  },
  tabContent: {
    marginTop: '20px',
  },
  tabPaneImg: {
    maxWidth: '80px',
    height: '80px',
    borderRadius: '10px',
    marginRight: '10px',
    backgroundColor: '#e0e0e0',
  },
  tabButtonText: {
    textAlign: 'left',
    margin: '0 auto',
  },
  mobileTabs: {
    flexDirection: 'column', // スマホサイズで縦並び
    alignItems: 'center',    // スマホサイズで左右中央に配置
    width: '100%',           // 幅を100%に設定
  },
  mobileTabButton: {
    width: '90%',            // スマホサイズでボタン幅を画面の90%に設定
  },
};

const MemberTabs = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <div style={styles.memberTabs}>
      <h2 style={styles.heading}>働くメンバーの紹介</h2>
      <div 
        style={{
          ...styles.tabs, 
          ...(isMobile ? styles.mobileTabs : {}),
        }}
      >
        <button
          style={{
            ...styles.tabButton,
            ...(activeTab === 'tab1' ? styles.tabButtonActive : {}),
            ...(isMobile ? styles.mobileTabButton : {}),
          }}
          onClick={() => handleTabClick('tab1')}
        >
          <div style={styles.tabButtonText}>
            <p>営業部 部長<br/>
              2002年入社</p>
          </div>
          <img style={styles.tabPaneImg} src={reqruitMember1TopImage} alt="2020年入社メンバー" />
        </button>
        <button
          style={{
            ...styles.tabButton,
            ...(activeTab === 'tab2' ? styles.tabButtonActive : {}),
            ...(isMobile ? styles.mobileTabButton : {}),
          }}
          onClick={() => handleTabClick('tab2')}
        >
          <div style={styles.tabButtonText}>
            <p>営業部<br/>
            2020年入社</p>
          </div>
          <img style={styles.tabPaneImg} src={reqruitMember2TopImage} alt="2022年入社メンバー" />
        </button>
      </div>

      <div style={styles.tabContent}>
        {activeTab === 'tab1' && (
          <MessageFromMember1 />
        )}
        {activeTab === 'tab2' && (
          <MessageFromMember2 />
        )}
      </div>
    </div>
  );
};

export default MemberTabs;
