import React from "react";
import styled, { css } from "styled-components";
import tw from "twin.macro";
import { Link } from "react-router-dom";

import showroom from "images/hoei-hp/hoei-top-showroom.jpg";

const Container = styled.div`
  ${tw`relative`}
  margin-top: 24px;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-left: 64px;
    padding-right: 64px;
  }
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-0`;
const Content = tw.div`mt-16`;

const Card = styled.div`
  ${tw`mt-2 md:flex justify-center items-center`}
`;

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded w-full h-80 md:h-144 bg-contain bg-center bg-no-repeat`, 
  tw`mx-0 sm:mx-4 md:mx-8`, 
  tw`flex-shrink-0 w-full`,
  css`
    @media (min-width: 768px) {
      width: 45%;
    }
  `
]);

const Details = styled.div`
  ${tw`mt-4 md:mt-0 w-full flex-grow`}
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 768px) {
    width: 55%;
  }

  @media (min-width: 640px) {
    ${tw`mx-8`}
  }

  @media (min-width: 768px) {
    ${tw`mx-4`}
  }

  @media (min-width: 1024px) {
    ${tw`mx-8`}
  }
`;

const Subtitle = styled.div`
  ${tw`font-bold tracking-wide text-secondary-100 text-black`}
  font-size: 16px;

  @media (min-width: 640px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 20px;
  }
`;

const Title = styled.h4`
  ${tw`font-bold text-black`}
  font-size: 20px;

  @media (min-width: 1024px) {
    font-size: 24px;
  }
`;

const Description = styled.p`
  ${tw`mt-2 text-black`}
  font-size: 14px;
  line-height: 1.4;

  @media (min-width: 640px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`;

const Button = styled(Link)`
  ${tw`inline-block mt-8 px-8 py-2 font-bold text-center rounded-full`}
  background-color: #7d7d7d;
  color: #fff; 
  border: 2px solid #000;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  text-decoration: none;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    width: 180%;
    height: 500%;
    background: #000;
    transition: all 0.5s ease-in-out;
    transform: rotate(45deg) translateX(0) translateY(0);
  }

  &:before {
    top: 0;
    left: calc(-150% + 50px);
  }

  &:after {
    top: -400%;
    right: calc(-150% + 50px);
  }

  &:hover {
    color: #e6b422;
    background-color: #000;
  }

  &:hover:before {
    transform: rotate(45deg) translateX(0) translateY(-50%);
  }

  &:hover:after {
    transform: rotate(45deg) translateX(0) translateY(50%);
  }

  span {
    position: relative;
    z-index: 1;
  }
`;

export default () => {
  return (
    <Container>
      <SingleColumn>
        <Content>
          <Card>
            <Image imageSrc={showroom} />
            <Details>
              <Subtitle>ショールーム</Subtitle>
              <Title>「食器の今がわかります」</Title>
              <Description>
                どんなにネットが便利になっても、伝えきれないことがあります。<br/>
                こと食器に関しては、触れてみないとわからないことだらけ。<br/><br/>
                色合い、さわり心地、重たさ、質感<br/>
                つまり「趣（おもむき）」<br/><br/>
                当社では、お客様が実際に手に取って商品に触れて頂き、納得いくまで商品選びが出来るように、「完全予約制」の「貸し切りショールーム」を用意しております。<br/>
              </Description>
              <Button to="/Showroom"><span>詳細はこちら</span></Button>
            </Details>
          </Card>
        </Content>
      </SingleColumn>
    </Container>
  );
};
