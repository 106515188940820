import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc, orderBy, query } from 'firebase/firestore';
import { firestore } from '../firebase/firebaseConfig';
import Modal from 'react-modal';

function CatalogOrderEditor({ isOpen, onClose }) {
  const [catalogs, setCatalogs] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (!isOpen) return;
    const fetchCatalogs = async () => {
      const catalogCollection = collection(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links");
      const catalogQuery = query(catalogCollection, orderBy('order', 'asc'));
      const catalogSnapshot = await getDocs(catalogQuery);
      const catalogData = catalogSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(catalog => catalog.open === true); // 公開中のみ取得
      setCatalogs(catalogData);
    };
    fetchCatalogs();
  }, [isOpen]);

  const handleSelect = (catalogId) => {
    if (orderList.includes(catalogId)) return; // 既に選択済みならスキップ
    
    const newOrderList = [...orderList, catalogId];
    setOrderList(newOrderList);
    if (newOrderList.length === catalogs.length) setIsUpdated(true);
  };

  const handleClear = () => {
    setOrderList([]);
    setIsUpdated(false);
  };

  const handleUpdateOrder = async () => {
    const batchUpdates = orderList.map((catalogId, index) => {
      const catalogRef = doc(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links", catalogId);
      return updateDoc(catalogRef, { order: index + 1 });
    });
    
    await Promise.all(batchUpdates);
    alert('表示順が更新されました');
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={{ content: { maxWidth: '600px', margin: 'auto', padding: '20px', borderRadius: '8px' } }}>
      <h2>表示順を変更</h2>
      <p>クリックした順番に数字が振られます</p>
      <div>
        {catalogs.map((catalog) => (
          <div key={catalog.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', cursor: 'pointer', backgroundColor: '#f1f1f1', padding: '10px', borderRadius: '8px' }} onClick={() => handleSelect(catalog.id)}>
            <span style={{ width: '30px', textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
              {orderList.includes(catalog.id) ? orderList.indexOf(catalog.id) + 1 : ''}
            </span>
            <img src={catalog.image} alt={catalog.title} style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '8px', margin: '0 10px' }} />
            <div style={{ flex: 1 }}>{catalog.title}</div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: '20px', display: 'flex', gap: '10px' }}>
        <button onClick={handleClear} style={{ padding: '10px 20px', backgroundColor: '#f0ad4e', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>クリア</button>
        <button onClick={handleUpdateOrder} disabled={!isUpdated} style={{ padding: '10px 20px', backgroundColor: isUpdated ? '#110b41ff' : '#808080', color: 'white', border: 'none', borderRadius: '4px', cursor: isUpdated ? 'pointer' : 'not-allowed' }}>更新</button>
        <button onClick={onClose} style={{ padding: '10px 20px', backgroundColor: '#d9534f', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>閉じる</button>
      </div>
    </Modal>
  );
}

export default CatalogOrderEditor;
