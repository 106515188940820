import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase/firebaseConfig';

const InstagramNewPostIm = () => {
  const [posts, setPosts] = useState([]);
  const [imageSize, setImageSize] = useState('160px');

  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      const size = Math.min(viewportWidth * 0.28, 300);
      setImageSize(`${size}px`);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      const postsRef = collection(firestore, 'instagram_posts');
      const q = query(
        postsRef,
        where('deleted', '==', false),
        orderBy('timestamp', 'desc'),
        limit(6)
      );

      try {
        const querySnapshot = await getDocs(q);
        const fetchedPosts = querySnapshot.docs.map(doc => doc.data());
        setPosts(fetchedPosts);
      } catch (error) {
        console.error("Error fetching posts: ", error);
      }
    };

    fetchPosts();
  }, []);

  const styles = {
    container: {
      width: '100%',
      maxWidth: '1080px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '80px auto',
    },
    title: {
      fontSize: '24px',
      textAlign: 'center',
      margin: '40px 0px',
    },
    postsContainer: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: '1px',
    },
    image: {
      width: imageSize,
      height: imageSize,
      objectFit: 'cover',
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>最新インスタ投稿</h2>
      <div style={styles.postsContainer}>
        {posts.map((post, index) => (
          <a key={index} href={post.permalink} target="_blank" rel="noopener noreferrer">
            <img 
              src={post.media_url} 
              alt="Instagram post" 
              style={styles.image} 
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default InstagramNewPostIm;