import React from "react";

const styles = {
  container: {
    width: '100%',
    margin: '0 auto',
    padding: '16px'
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px'
  },
};

const Business = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>事業内容</h1>
        <p>
        当社は、レストランチェーン店、居酒屋、ダイニング等をはじめとする
        飲食店全般の食器・厨房備品を販売・企画・開発しております。<br/><br/>
        消費地である東京に営業拠点を置くことで、
        常に外食産業の最新情報・トレンドを抑えた商品のご提案をさせていただきます。<br/><br/>
        また、美濃焼の産地であります岐阜県土岐市に物流拠点を置くことで、
        良いものを迅速にお届けできる体制を整えております。
        </p>
    </div>
  );
};

export default Business;