import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, orderBy, limit, doc, updateDoc, deleteDoc, where } from 'firebase/firestore';
import { firestore } from '../firebase/firebaseConfig';
import NewsPreview from './NewsPreview';

function NewsList() {
  const [newsList, setNewsList] = useState([]);
  const [filter, setFilter] = useState('published'); // 'all', 'published', 'private', 'archived'

  const fetchNews = useCallback(async () => {
    const newsCollection = collection(firestore, 'news');
    let newsQuery;

    if (filter === 'published') {
      newsQuery = query(newsCollection, where('publication_status', '==', 1), orderBy('publish_date', 'desc'), limit(16));
    } else if (filter === 'private') {
      newsQuery = query(newsCollection, where('publication_status', '==', 0), orderBy('publish_date', 'desc'), limit(16));
    } else if (filter === 'archived') {
      newsQuery = query(newsCollection, where('publication_status', '==', 9), orderBy('publish_date', 'desc'), limit(16));
    } else {
      newsQuery = query(newsCollection, where('publication_status', 'in', [0, 1]), orderBy('publish_date', 'desc'), limit(16));
    }

    const newsSnapshot = await getDocs(newsQuery);
    const newsData = newsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setNewsList(newsData);
  }, [filter]);

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const toggleStatus = async (newsId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    const newsRef = doc(firestore, 'news', newsId);
    await updateDoc(newsRef, { publication_status: newStatus });

    setNewsList(prevList =>
      prevList.map(news =>
        news.id === newsId ? { ...news, publication_status: newStatus } : news
      )
    );
  };

  const unarchiveNews = async (newsId) => {
    if (window.confirm('非公開に戻しますか？')) {
      const newsRef = doc(firestore, 'news', newsId);
      await updateDoc(newsRef, { publication_status: 0 });
      
      // リストを再取得して画面を更新
      fetchNews();
    }
  };

  const deleteNews = async (newsId) => {
    if (window.confirm('本当に削除しますか？削除されたら元に戻すことはできません。')) {
      const newsRef = doc(firestore, 'news', newsId);
      await deleteDoc(newsRef);

      setNewsList(prevList => prevList.filter(news => news.id !== newsId));
    }
  };

  const archiveNews = async (newsId) => {
    if (window.confirm('アーカイブしますか？')) {
      const newsRef = doc(firestore, 'news', newsId);
      await updateDoc(newsRef, { publication_status: 9 });

      setNewsList(prevList => prevList.filter(news => news.id !== newsId));
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      minHeight: '100vh',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '20px',
    },
    filterButton: {
      padding: '10px 20px',
      margin: '0 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    verticalDivider: {
      height: '30px',
      width: '1px',
      backgroundColor: '#808080',
      margin: '0 10px',
    },
    headerGroup: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      maxWidth: '800px',
      padding: '10px',
      backgroundColor: '#f1f1f1',
      borderRadius: '8px',
      marginBottom: '8px',
    },
    headerInfo: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '10px',
    },
    headerItem: {
      textAlign: 'left',
    },
    smallText: {
      fontSize: '10px',
      textAlign: 'left',
    },
    statusButton: {
      padding: '5px 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      marginRight: '10px',
    },
    archiveButton: {
      padding: '5px 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      marginLeft: 'auto',
    },
    newsContainer: {
      width: '100%',
      maxWidth: '800px',
      marginBottom: '16px',
    },
    noNewsMessage: {
      marginTop: '20px',
      fontSize: '16px',
      color: '#808080',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.filterContainer}>
        <button
          onClick={() => setFilter('all')}
          style={{
            ...styles.filterButton,
            backgroundColor: filter === 'all' ? '#110b41ff' : '#808080',
            color: 'white',
          }}
        >
          すべて
        </button>
        <button
          onClick={() => setFilter('published')}
          style={{
            ...styles.filterButton,
            backgroundColor: filter === 'published' ? '#110b41ff' : '#808080',
            color: 'white',
          }}
        >
          公開中
        </button>
        <button
          onClick={() => setFilter('private')}
          style={{
            ...styles.filterButton,
            backgroundColor: filter === 'private' ? '#110b41ff' : '#808080',
            color: 'white',
          }}
        >
          非公開
        </button>
        <div style={styles.verticalDivider}></div>
        <button
          onClick={() => setFilter('archived')}
          style={{
            ...styles.filterButton,
            backgroundColor: filter === 'archived' ? '#110b41ff' : '#808080',
            color: 'white',
          }}
        >
          アーカイブ
        </button>
      </div>
      {newsList.length === 0 ? (
        <div style={styles.noNewsMessage}>該当のお知らせはありません</div>
      ) : (
        newsList.map((news) => (
          <div key={news.id} style={styles.newsContainer}>
            <div style={styles.headerGroup}>
              {news.publication_status === 9 ? (
                <button
                  onClick={() => unarchiveNews(news.id)}
                  style={{
                    ...styles.statusButton,
                    backgroundColor: '#808080',
                    color: 'white',
                  }}
                >
                  非公開に戻す
                </button>
              ) : (
                <button
                  onClick={() => toggleStatus(news.id, news.publication_status)}
                  style={{
                    ...styles.statusButton,
                    backgroundColor: news.publication_status === 1 ? '#110b41ff' : '#808080',
                    color: 'white',
                  }}
                >
                  {news.publication_status === 1 ? '公開中' : '非公開'}
                </button>
              )}
              <div style={styles.headerInfo}>
                <div style={styles.headerItem}>投稿者: {news.user_name}</div>
                <div style={styles.smallText}>ID: {news.user_id}</div>
                <div style={styles.smallText}>メール: {news.user_email}</div>
              </div>
              {news.publication_status === 9 ? (
                <button
                  onClick={() => deleteNews(news.id)}
                  style={{
                    ...styles.archiveButton,
                    backgroundColor: '#d9534f',
                    color: 'white',
                  }}
                >
                  削除
                </button>
              ) : (
                <button
                  onClick={() => archiveNews(news.id)}
                  style={{
                    ...styles.archiveButton,
                    backgroundColor: '#333333',
                    color: 'white',
                  }}
                >
                  アーカイブ
                </button>
              )}
            </div>
            <NewsPreview
              title={news.title}
              content={news.html_content}
              image={news.image_url || null}
              createdAt={news.publish_date}
            />
          </div>
        ))
      )}
    </div>
  );
}

export default NewsList;
