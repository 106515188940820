import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, orderBy, where, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { firestore, storage } from '../firebase/firebaseConfig'; // Ensure storage is exported from firebaseConfig
import { ref, deleteObject } from 'firebase/storage';
import CatalogOrderEditor from './CatalogOrderEditer';
import CatalogEditer from './CatalogEditer'; // Import your CatalogEditer component

function CatalogList() {
  const [catalogs, setCatalogs] = useState([]);
  const [filter, setFilter] = useState('open');
  const [errorMessage, setErrorMessage] = useState("");
  const [isOrderEditorOpen, setIsOrderEditorOpen] = useState(false);
  const [isEditerOpen, setIsEditerOpen] = useState(false);
  const [currentCatalog, setCurrentCatalog] = useState(null); // To store the catalog being edited

  const fetchCatalogs = useCallback(async () => {
    const catalogCollection = collection(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links");
    let catalogQuery = query(catalogCollection, orderBy('order', 'asc'));

    const catalogSnapshot = await getDocs(catalogQuery);
    let catalogData = catalogSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      archive: doc.data().archive || false,
    }));

    // Apply filter
    if (filter === 'open') {
      catalogData = catalogData.filter(catalog => catalog.open === true && !catalog.archive);
    } else if (filter === 'closed') {
      catalogData = catalogData.filter(catalog => catalog.open === false && !catalog.archive);
    } else if (filter === 'archived') {
      catalogData = catalogData.filter(catalog => catalog.archive === true);
    } else {
      catalogData = catalogData.filter(catalog => !catalog.archive);
    }

    setCatalogs(catalogData);
  }, [filter]);

  useEffect(() => {
    fetchCatalogs();
  }, [fetchCatalogs]);

  const toggleStatus = async (catalogId, currentStatus) => {
    const newStatus = !currentStatus;
    const catalogRef = doc(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links", catalogId);
    await updateDoc(catalogRef, { open: newStatus });

    setCatalogs(prevList =>
      prevList.map(catalog =>
        catalog.id === catalogId ? { ...catalog, open: newStatus } : catalog
      )
    );
  };

  const archiveCatalog = async (catalogId, currentOpenStatus) => {
    if (currentOpenStatus) {
      setErrorMessage("アーカイブする場合は、非公開にしてください。");
      return;
    }

    const catalogRef = doc(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links", catalogId);
    await updateDoc(catalogRef, { archive: true });

    setCatalogs(prevList => prevList.filter(catalog => catalog.id !== catalogId));
  };

  const restoreFromArchive = async (catalogId) => {
    const catalogRef = doc(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links", catalogId);
    await updateDoc(catalogRef, { archive: false });

    setCatalogs(prevList => prevList.filter(catalog => catalog.id !== catalogId));
  };

  // Helper function to extract storage path from image URL
  const getStoragePathFromUrl = (imageUrl) => {
    try {
      const url = new URL(imageUrl);
      const path = decodeURIComponent(url.pathname.split('/o/')[1].split('?')[0]);
      return path;
    } catch (error) {
      console.error("Invalid image URL:", error);
      return null;
    }
  };

  // Helper function to delete image from storage
  const deleteImageFromStorage = async (imageUrl) => {
    const imagePath = getStoragePathFromUrl(imageUrl);
    if (!imagePath) {
      throw new Error("Cannot parse storage path from image URL.");
    }
    
    const imageRef = ref(storage, imagePath);
    await deleteObject(imageRef);
  };

  const deleteCatalog = useCallback(async (catalogId) => {
    if (window.confirm("削除しますか？削除後は復元できません。")) {
      try {
        // Find the catalog to get the image URL
        const catalog = catalogs.find(c => c.id === catalogId);
        if (!catalog) {
          setErrorMessage("カタログが見つかりません。");
          return;
        }

        // Delete the image from Firebase Storage
        if (catalog.image) {
          await deleteImageFromStorage(catalog.image);
        }

        // Delete the Firestore document
        const catalogRef = doc(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links", catalogId);
        await deleteDoc(catalogRef);

        // Update the state
        setCatalogs(prevList => prevList.filter(catalog => catalog.id !== catalogId));
        setErrorMessage(""); // Clear any previous error messages
      } catch (error) {
        console.error("Error deleting catalog:", error);
        setErrorMessage("カタログの削除中にエラーが発生しました。");
      }
    }
  }, [catalogs, firestore, storage]);

  const handleEditCatalog = (catalog) => {
    setCurrentCatalog(catalog);
    setIsEditerOpen(true);
  };

  const styles = {
    container: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    filterContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '20px',
    },
    filterButton: {
      padding: '10px 20px',
      margin: '0 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
    },
    catalogContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '800px', 
      marginBottom: '16px',
    },
    catalogItem: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#f1f1f1',
      padding: '10px',
      borderRadius: '8px',
      marginBottom: '8px',
      position: 'relative', // To position the edit button
    },
    catalogImage: {
      width: 'auto',
      height: '80px',
      objectFit: 'cover',
      borderRadius: '8px',
      margin: '0px 8px',
    },
    catalogInfo: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      flex: 1,
    },
    catalogLink: {
      fontSize: '12px',
      color: '#007bff',
      wordBreak: 'break-word',
      textDecoration: 'none',
    },
    smallText: {
      fontSize: '12px',
      color: '#555',
      textAlign: 'left', 
    },
    statusButton: {
      padding: '5px 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      marginRight: '10px',
    },
    actionButton: {
      padding: '5px 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.3s',
      marginLeft: '10px',
    },
    orderEditButton: {
      padding: '5px 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: '#0067C0',
      transition: 'background-color 0.3s',
      color: 'white',
      marginBottom: '16px',
    },
    editButton: {
      padding: '5px 10px',
      borderRadius: '4px',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: '#5bc0de',
      color: 'white',
      transition: 'background-color 0.3s',
    },
    verticalDivider: {
        height: '30px',
        width: '1px',
        backgroundColor: '#808080',
        margin: '0 10px',
      },
  };

  return (
    <div style={styles.container}>
      <div style={styles.filterContainer}>
        <button onClick={() => setFilter('all')} style={{ ...styles.filterButton, backgroundColor: filter === 'all' ? '#110b41ff' : '#808080', color: 'white' }}>すべて</button>
        <button onClick={() => setFilter('open')} style={{ ...styles.filterButton, backgroundColor: filter === 'open' ? '#110b41ff' : '#808080', color: 'white' }}>公開中</button>
        <button onClick={() => setFilter('closed')} style={{ ...styles.filterButton, backgroundColor: filter === 'closed' ? '#110b41ff' : '#808080', color: 'white' }}>非公開</button>
        <div style={styles.verticalDivider}></div>
        <button onClick={() => setFilter('archived')} style={{ ...styles.filterButton, backgroundColor: filter === 'archived' ? '#110b41ff' : '#808080', color: 'white' }}>アーカイブ</button>
      </div>
      {filter === 'open' && (
        <button style={styles.orderEditButton} onClick={() => setIsOrderEditorOpen(true)}>
          表示順の変更
        </button>
      )}

      <CatalogOrderEditor 
        isOpen={isOrderEditorOpen} 
        onClose={() => {
          setIsOrderEditorOpen(false);
          fetchCatalogs();  // Refresh data after closing
        }} 
      />

      <CatalogEditer 
        isOpen={isEditerOpen} 
        onClose={() => {
          setIsEditerOpen(false);
          fetchCatalogs(); // Refresh data after closing
        }}
        catalog={currentCatalog} // Pass the catalog data to edit
      />

      {errorMessage && <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>}

      {catalogs.map((catalog) => (
        <div key={catalog.id} style={styles.catalogContainer}>
          <div style={styles.catalogItem}>
            {filter === 'archived' ? (
              <button onClick={() => restoreFromArchive(catalog.id)} style={{ ...styles.statusButton, backgroundColor: '#808080', color: 'white' }}>
                非公開に戻す
              </button>
            ) : (
              <button onClick={() => toggleStatus(catalog.id, catalog.open)} style={{ ...styles.statusButton, backgroundColor: catalog.open ? '#110b41ff' : '#808080', color: 'white' }}>
                {catalog.open ? '公開中' : '非公開'}
              </button>
            )}

            <img src={catalog.image} alt={catalog.title} style={styles.catalogImage} />
            <div style={styles.catalogInfo}>
              <div>{catalog.title}</div>
              <a href={catalog.url} target="_blank" rel="noopener noreferrer" style={styles.catalogLink}>
                {catalog.url}
              </a>
              <div style={styles.smallText}>最終更新: {new Date(catalog.last_update.seconds * 1000).toLocaleDateString()}</div>
            </div>

            {/* Edit Button */}
            <button onClick={() => handleEditCatalog(catalog)} style={styles.editButton}>
              編集
            </button>

            <button onClick={() => filter === 'archived' ? deleteCatalog(catalog.id) : archiveCatalog(catalog.id, catalog.open)} style={{ ...styles.actionButton, backgroundColor: filter === 'archived' ? '#d9534f' : '#333333', color: 'white' }}>
              {filter === 'archived' ? '削除' : 'アーカイブ'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CatalogList;
