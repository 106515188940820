import React, { useEffect } from "react";
import Header from "components/headers/lp-header-top";
import Top from "components/hero/lp-top";
import Business from "components/features/lp-body";
import Showroom from "components/features/lp-body-showroom";
import Recruit from "components/features/lp-body-members";
import Footer from "components/footers/lp-footer";
import InstagramNewPostIm from "components/features/lp-body-instagram_im";
import NewsWide from "components/features/news-wide";
import { useLocation } from "react-router-dom";
import CatalogContents from "components/catalog/CatalogContents";

export default () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/" && location.hash.replace("#", "") === "catalog") {
      const element = document.getElementById("catalog");
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 500); 
      }
    }
  }, [location]); 

  return (
    <>
      <Header />
      <Top />
      <NewsWide />
      <InstagramNewPostIm />
      <Business />
      <Showroom />
      <Recruit />
      <CatalogContents />
      <Footer />
    </>
  );
};
