import React, { useState, useEffect } from 'react';

const MinoCenter = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  
  const headerImage = require('../../images/mino/mino-top.jpg');

  useEffect(() => {
    if (selectedImage) {
      setModalVisible(true);
    } else {
      setTimeout(() => {
        setModalVisible(false);
      }, 500);
    }
  }, [selectedImage]);

  const styles = {
    headerSection: {
      marginBottom: '8px',
    },
    headerContainer: {
      width: '100%',
      height: '80vh',
      marginBottom: '40px',
    },
    headerImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    textContent: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '0 20px',
      textAlign: 'center',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      opacity: selectedImage ? 1 : 0,
      visibility: modalVisible ? 'visible' : 'hidden',
      transition: 'opacity 0.5s ease',
    },
    modalImageWrapper: {
      width: '80%',
      height: '80%',
      maxWidth: '1200px',
      maxHeight: '800px',
      overflow: 'hidden',
    },
    modalImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  };

  return (
    <div>
      <section style={styles.headerSection}>
        <div style={styles.headerContainer}>
          <img 
            src={headerImage} 
            alt="Header" 
            style={styles.headerImage} 
          />
        </div>
        <div style={styles.textContent}>
          <h2 style={styles.title}>豊栄/HOEI 美濃物流センター</h2>
        </div>
      </section>

      <div
        style={styles.modalOverlay}
        onClick={() => setSelectedImage(null)}
      >
        {selectedImage && (
          <div style={styles.modalImageWrapper}>
            <img
              src={selectedImage}
              alt="Enlarged"
              style={styles.modalImage}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MinoCenter;