import React from "react";
import { Globe } from "lucide-react";
import styled from "styled-components";
import tw from "twin.macro";
import { useLocation } from "react-router-dom";

const StyledTranslateButton = styled.a`
  ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 font-semibold tracking-wide transition duration-300 pb-1 border-b-2 border-transparent flex items-center`}
  &:hover {
    color: #110b41ff;
    border-color: #110b41ff;
  }
  gap: 8px;
`;

const TextContainer = styled.div`
  ${tw`flex flex-col`}
  line-height: 1.2;
`;

const TranslateButton = () => {
  const location = useLocation();

  const handleTranslate = () => {
    const domain = window.location.origin;
    const fullUrl = `${domain}${location.pathname}${location.search}`;
    const translateUrl = `https://translate.google.com/translate?sl=ja&tl=en&u=${encodeURIComponent(fullUrl)}`;
    window.location.href = translateUrl;
  };

  return (
    <StyledTranslateButton as="button" onClick={handleTranslate}>
      <Globe size={16} />
      <TextContainer>
        <span>Translate</span>
        <span>By Google</span>
      </TextContainer>
    </StyledTranslateButton>
  );
};

export default TranslateButton;