import React, { useEffect, useState } from "react";
import { getFirestore, doc, collection, getDocs, getDoc } from "firebase/firestore";
import sampleImage from "../../images/catalog/sample_image_catalog.jpg"; // 画像をimport

// CSSをconstで定義
const styles = {
  headerContainer: {
    position: 'relative',
    width: '100%',
    margin: '80px 0px',
  },
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    textAlign: "center",
    padding: "20px",
  },
  catalogContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "16px",
    maxWidth: "1200px",
  },
  singleCatalog: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    cursor: "pointer",
    margin: "8px",
    width: "240px",
    height: "360px", 
  },
  title: {
    fontSize: "24px",
    margin: "32px",
    marginBottom: "64px",
    fontWeight: "bold",
  },
  frame: {
    border: "16px solid lightgray",
    padding: "4px",
    display: "inline-block",
    overflow: "hidden",
    position: "relative",
    width: "240px",
    height: "300px", // 画像部分の高さを固定
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform 0.5s ease",
    display: "block",
  },

  imageHover: {
    transform: "scale(1.05)",
  },
  catalogTitle: {
    marginTop: "8px",
    fontSize: "14px",
    textAlign: "center",
    minHeight: "36px", // タイトルの高さを最小2行分確保
    lineHeight: "1.2",
    whiteSpace: "normal", // 折り返し可能にする
    wordBreak: "break-word",
  },
  infoContainer: {
    maxWidth: "760px",
    textAlign: "left",
    backgroundColor: "#f8f8f8",
    padding: "16px",
    margin: "16px",
    borderRadius: "8px",
  },
  infoTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "12px",
    borderBottom: "2px solid gray",
    paddingBottom: "4px",
  },
  infoText: {
    fontSize: "14px",
    lineHeight: "1.6",
  },
  loading: {
    fontSize: "14px",
    color: "gray",
  },
  error: {
    fontSize: "14px",
    color: "red",
  },
  // 新規追加：円形アイコンのスタイル
  circle: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    border: "1px solid lightgray",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#c8cacf",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    transition: "opacity 0.5s ease, background-color 0.8s ease, color 0.8s ease",
    zIndex: 2,
  },
  // 通常時は非表示
  circleHidden: {
    opacity: 0,
  },
  // ホバー時のスタイル
  circleHover: {
    opacity: 1,
    backgroundColor: "rgba(200, 202, 207, 0.8)", // 透過させた背景色
    color: "white",
    // 外枠の線（borderプロパティ）は削除済み
  },
};

const CatalogContents = () => {
  const [catalogData, setCatalogData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null); // どのアイテムがホバーされているかを管理
  const [standardDate, setStandardDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        const db = getFirestore();
        const catalogRef = doc(db, "catalogs", "3DEUDyVarP8SeqGhAe83");
        const linksCollection = collection(catalogRef, "links");
        const snapshot = await getDocs(linksCollection);

        let catalogs = [];

        for (const docSnap of snapshot.docs) {
          const data = docSnap.data();
          if (data.open) {
            catalogs.push({
              title: data.title,
              url: data.url,
              order: data.order,
              image: data.image || sampleImage, // 画像がない場合はデフォルト画像
            });
          }
        }

        // order の昇順にソート
        catalogs.sort((a, b) => a.order - b.order);

        setCatalogData(catalogs);
      } catch (error) {
        console.error("Firestoreからのデータ取得に失敗:", error);
      }
    };

    const fetchStandardDate = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, "catalogs", "3DEUDyVarP8SeqGhAe83");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.standard_date) {
            setStandardDate(data.standard_date.toDate());
          } else {
            setStandardDate(null);
          }
        } else {
          console.log("指定されたドキュメントは存在しません。");
          setStandardDate(null);
        }
      } catch (error) {
        console.error("Firestoreからの標準日取得に失敗:", error);
        setFetchError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCatalogData();
    fetchStandardDate();
  }, []);

  // 日付をフォーマットする関数
  const formatDate = (date) => {
    if (!date) return '未設定';
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}年${month}月${day}日`;
  };

  return (
    <div id="catalog" style={styles.headerContainer}>
      <div style={styles.page}>
        {/* メインタイトル */}
        <h2 style={styles.title}>Catalog</h2>

        {/* カタログリスト */}
        <div style={styles.catalogContainer}>
          {catalogData.map((catalog, index) => (
            <a
              key={index}
              href={catalog.url}
              target="_blank"
              rel="noopener noreferrer"
              style={styles.singleCatalog}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div style={styles.frame}>
                {/* 画像 */}
                <img
                  src={catalog.image}
                  alt="カタログ表紙"
                  style={hoveredIndex === index ? { ...styles.image, ...styles.imageHover } : styles.image}
                />
                {/* 画像上中央に配置する円形アイコン（ホバー時のみ表示） */}
                <div style={hoveredIndex === index ? { ...styles.circle, ...styles.circleHover } : { ...styles.circle, ...styles.circleHidden }}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 12H20M15 7l5 5-5 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>

              {/* カタログタイトル */}
              <p style={styles.catalogTitle}>{catalog.title}</p>
            </a>
          ))}
        </div>

                {/* Information セクション */}
                <div style={styles.infoContainer}>
          <h3 style={styles.infoTitle}>Guidance</h3>
          <ul style={{ paddingLeft: "20px", listStyleType: "disc", fontSize: "14px", lineHeight: "1.6" }}>
            <li>
              掲載内容（価格・仕様・在庫状況など）は、最新の情報を反映するよう努めておりますが、予告なく変更される場合がございます。
            </li>
            <li>
              記載されている価格は{isLoading ? '読み込み中...' : fetchError ? `エラー: ${fetchError}` : ` ${formatDate(standardDate)} `}時点のものです。
            </li>
            <li>
              商品画像はイメージです。実際の色や形状に誤差が生じる場合がございますので、あらかじめご了承ください。
            </li>
            <li>
              商品に関するご質問やご不明点がございましたら、当社窓口または担当営業までお問い合わせください。
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default CatalogContents;
