import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore, storage } from '../firebase/firebaseConfig'; 
import { v4 as uuidv4 } from 'uuid'; // uuidのインポート

function CatalogEditer({ isOpen, onClose, catalog }) {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [image, setImage] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState('');
  const [order, setOrder] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (catalog) {
      setTitle(catalog.title);
      setUrl(catalog.url);
      setCurrentImageUrl(catalog.image);
      setOrder(catalog.order);
    }
  }, [catalog]);

  useEffect(() => {
    // 新しい画像をアップロードする場合のみ画像の検証が必要
    setIsFormValid(title.trim() !== '' && url.trim() !== '');
  }, [title, url]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        alert('サポートされていないファイルタイプです。JPEG, PNG, GIFのみアップロードできます。');
        return;
      }

      if (file.size > maxSize) {
        alert('ファイルサイズが大きすぎます。5MB以下のファイルをアップロードしてください。');
        return;
      }

      setImage(file);
    } else {
      setImage(null);
    }
  };

  const uploadNewImage = async () => {
    if (!image) return currentImageUrl;

    // 既存の画像を削除
    if (currentImageUrl) {
      const oldImagePath = getStoragePathFromUrl(currentImageUrl);
      if (oldImagePath) {
        const oldImageRef = ref(storage, oldImagePath);
        try {
          await deleteObject(oldImageRef);
          console.log('既存の画像を削除しました');
        } catch (error) {
          console.error('既存の画像削除に失敗:', error);
          // 必要に応じてエラーハンドリング
        }
      }
    }

    // 新しい画像をアップロード
    const uniqueId = uuidv4(); // UUIDの生成
    const fileExtension = image.name.split('.').pop();
    const uniqueFileName = `${uniqueId}.${fileExtension}`;
    const storageRefNew = ref(storage, `catalogs/${uniqueFileName}`);
    
    try {
      await uploadBytes(storageRefNew, image);
      const newImageUrl = await getDownloadURL(storageRefNew);
      console.log('新しい画像をアップロードしました');
      return newImageUrl;
    } catch (error) {
      console.error('新しい画像のアップロードに失敗:', error);
      throw error;
    }
  };

  // 画像URLからストレージパスを取得するヘルパー関数
  const getStoragePathFromUrl = (imageUrl) => {
    try {
      const url = new URL(imageUrl);
      const path = decodeURIComponent(url.pathname.split('/o/')[1].split('?')[0]);
      return path;
    } catch (error) {
      console.error("無効な画像URLです:", error);
      return null;
    }
  };

  const handleSave = async () => {
    if (!catalog) return;

    setIsSubmitting(true);

    try {
      const updatedImageUrl = await uploadNewImage();

      const catalogRef = doc(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links", catalog.id);
      await updateDoc(catalogRef, {
        title,
        url,
        image: updatedImageUrl,
        order,
        last_update: new Date(),
      });

      console.log('カタログが更新されました');
      setShowNotification(true);
    } catch (e) {
      console.error('Firestoreへの更新に失敗:', e);
      alert(`エラー: ${e.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNotificationOk = () => {
    setShowNotification(false);
    clearForm();
    onClose();
  };

  const clearForm = () => {
    setTitle('');
    setUrl('');
    setImage(null);
    setCurrentImageUrl('');
    setOrder(1);
  };

  const styles = {
    inputGroup: {
      marginBottom: '20px',
      textAlign: 'left',
    },
    EditTitle: {
      display: 'block',
      marginBottom: '8px',
      fontWeight: 'bold',
    },
    label: {
      display: 'block',
      marginBottom: '8px',
      fontWeight: 'bold',
    },
    inputField: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
    },
    currentImage: {
      width: '100px',
      height: 'auto',
      objectFit: 'cover',
      marginTop: '10px',
    },
    submitButton: {
      padding: '10px 20px',
      backgroundColor: '#110b41ff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      width: '48%',
    },
    cancelButton: {
      padding: '10px 20px',
      backgroundColor: '#d9534f',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      width: '48%',
    },
    buttonGroup: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    notification: {
      backgroundColor: '#f0f8ff',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      boxShadow: '0 0 10px rgba(0,0,0,0.3)',
      marginTop: '20px',
    },
    okButton: {
      padding: '10px 20px',
      backgroundColor: '#110b41ff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '20px',
    },
  };
  
  const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      padding: '20px',
      boxSizing: 'border-box',
    },
    modal: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      width: '100%',
      maxWidth: '600px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.26)',
      position: 'relative',
    },
    form: {
      width: '100%',
    },
  };

  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
      <div style={styles.EditTitle}>
          <p>基準日設定</p>
        </div>
        <form onSubmit={(e) => e.preventDefault()} style={modalStyles.form}>
          <div style={styles.inputGroup}>
            <label style={styles.label} htmlFor="title">タイトル:</label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={styles.inputField}
            />
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label} htmlFor="url">URL:</label>
            <input
              id="url"
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              style={styles.inputField}
            />
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label} htmlFor="image">カタログ画像:</label>
            <input
              id="image"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={styles.inputField}
            />
            {currentImageUrl && !image && (
              <div style={{ marginTop: '10px' }}>
                <img src={currentImageUrl} alt="Current Catalog" style={styles.currentImage} />
                <p>現在の画像</p>
              </div>
            )}
            {image && (
              <div style={{ marginTop: '10px' }}>
                <img src={URL.createObjectURL(image)} alt="New Catalog" style={styles.currentImage} />
                <p>新しい画像プレビュー</p>
              </div>
            )}
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label} htmlFor="order">表示順 (order):</label>
            <input
              id="order"
              type="number"
              value={order}
              onChange={(e) => setOrder(Number(e.target.value))}
              style={styles.inputField}
              min="1"
            />
          </div>

          <div style={styles.buttonGroup}>
            <button
              type="button"
              onClick={handleSave}
              style={{
                ...styles.submitButton,
                backgroundColor: isFormValid ? '#110b41ff' : '#ccc',
                cursor: isFormValid && !isSubmitting ? 'pointer' : 'not-allowed',
              }}
              disabled={!isFormValid || isSubmitting}
            >
              {isSubmitting ? '保存中...' : '保存'}
            </button>
            <button
              type="button"
              onClick={onClose}
              style={styles.cancelButton}
            >
              キャンセル
            </button>
          </div>
        </form>

        {showNotification && (
          <div style={styles.notification}>
            <p>カタログが更新されました。</p>
            <button onClick={handleNotificationOk} style={styles.okButton}>OK</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CatalogEditer;
