import React from 'react';
import CatalogUpload from './CatalogUpload';
import CatalogList from './CatalogList';
import CatalogDateSetter from './CatalogDateSetter';
import CatalogContents from 'components/catalog/CatalogContents';

const CatalogManagement = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px', // 必要に応じてパディングを追加
    },
    previewTitle: {
      display: 'block',
      fontWeight: 'bold',
      marginTop: '20px', // Divider の後にスペースを追加
    },
    divider: {
      width: '100%', // 横幅を100%に設定
      border: 'none',
      borderTop: '1px solid #ccc', // 灰色の線
      margin: '20px 0', // 上下にスペースを追加
    },
  };

  return (
    <div style={styles.container}>
      <CatalogDateSetter />
      <CatalogUpload />
      <CatalogList />
      <hr style={styles.divider} />
      <div style={styles.previewTitle}>
        <p>プレビュー</p>
      </div>
      <CatalogContents />
    </div>
  );
};

export default CatalogManagement;
