import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore';
import { firestore, storage } from '../firebase/firebaseConfig'; 
import { useUser } from '../admin-function/UserContext'; 
import { v4 as uuidv4 } from 'uuid'; // uuidのインポート

function CatalogUpload() {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [image, setImage] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [order, setOrder] = useState(1);
  const [showNotification, setShowNotification] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [registrationType, setRegistrationType] = useState('url'); // 'url' または 'file'
  const [isLoading, setIsLoading] = useState(false); // ローディング状態の追加
  const user = useUser();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      width: '100%',
    },

    uploadTitle: {
        display: 'block',
        marginBottom: '8px',
        fontWeight: 'bold',
    },
  
    form: {
      width: '100%',
      maxWidth: '800px',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'left',
      position: 'relative',
    },
    inputGroup: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      marginBottom: '8px',
      fontWeight: 'bold',
    },
    inputField: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    submitButton: {
      padding: '10px 20px',
      backgroundColor: isFormValid ? '#110b41ff' : '#ccc',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: isFormValid ? 'pointer' : 'not-allowed',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    notification: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    },
    okButton: {
      padding: '10px',
      backgroundColor: '#110b41ff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '20px',
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '20px',
    },
    radioOption: {
      marginRight: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    radioInput: {
      marginRight: '5px',
    },
    // ローディングインジケーター用のスタイル
    loadingIndicator: {
      display: 'inline-block',
      width: '20px',
      height: '20px',
      marginRight: '10px',
      border: '3px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '50%',
      borderTopColor: '#fff',
      animation: 'spin 1s ease-in-out infinite',
    },
    // カバーレイヤー（フォーム全体を覆う）
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      display: isLoading ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      zIndex: 10,
    },
    loadingText: {
      marginLeft: '15px',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    // キーフレームアニメーション用のスタイル（CSS in JSなので文字列として定義）
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  };

  // アニメーションをスタイルシートに追加
  useEffect(() => {
    // すでに存在するスタイルシートがあるか確認
    let styleElement = document.getElementById('catalogUploadStyles');
    
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = 'catalogUploadStyles';
      styleElement.innerHTML = `
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `;
      document.head.appendChild(styleElement);
    }
    
    // コンポーネントのアンマウント時にスタイルシートを削除
    return () => {
      if (styleElement && document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);

  useEffect(() => {
    const fetchMaxOrder = async () => {
      try {
        const db = getFirestore();
        const linksCollection = collection(db, "catalogs", "3DEUDyVarP8SeqGhAe83", "links");
        const snapshot = await getDocs(linksCollection);
        
        let maxOrder = 0;
        snapshot.docs.forEach(doc => {
          const data = doc.data();
          if (data.order && data.order > maxOrder) {
            maxOrder = data.order;
          }
        });

        setOrder(maxOrder + 1);
      } catch (error) {
        console.error("Firestoreのorder取得に失敗:", error);
      }
    };

    fetchMaxOrder();
  }, []);

  useEffect(() => {
    if (registrationType === 'url') {
      // URL登録の場合、タイトル、URL、画像が必須
      setIsFormValid(title.trim() !== '' && url.trim() !== '' && image !== null);
    } else {
      // ファイル登録の場合、タイトル、PDFファイル、画像が必須
      setIsFormValid(title.trim() !== '' && pdfFile !== null && image !== null);
    }
  }, [title, url, image, pdfFile, registrationType]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        alert('サポートされていないファイルタイプです。JPEG, PNG, GIFのみアップロードできます。');
        return;
      }

      if (file.size > maxSize) {
        alert('ファイルサイズが大きすぎます。5MB以下のファイルをアップロードしてください。');
        return;
      }

      setImage(file);
    } else {
      setImage(null);
    }
  };

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== 'application/pdf') {
        alert('PDFファイルのみアップロードできます。');
        setPdfFile(null);
        return;
      }

      const maxSize = 500 * 1024 * 1024;
      if (file.size > maxSize) {
        alert('ファイルサイズが大きすぎます。500MB以下のファイルをアップロードしてください。');
        setPdfFile(null);
        return;
      }

      setPdfFile(file);
    } else {
      setPdfFile(null);
    }
  };

  const uploadCatalog = async () => {
    try {
      setIsLoading(true); // ローディング開始
      
      let imageUrl = '';
      let catalogUrl = '';

      // 画像のアップロード
      if (image) {
        const uniqueId = uuidv4();
        const fileExtension = image.name.split('.').pop();
        const uniqueFileName = `${uniqueId}.${fileExtension}`;
        const storageRef = ref(storage, `catalogs/${uniqueFileName}`);
        
        await uploadBytes(storageRef, image);
        imageUrl = await getDownloadURL(storageRef);
      }

      // PDFファイルの場合、Storageにアップロード
      if (registrationType === 'file' && pdfFile) {
        const uniqueId = uuidv4();
        const uniqueFileName = `${uniqueId}.pdf`;
        const storageRef = ref(storage, `catalogfiles/${uniqueFileName}`);
        
        await uploadBytes(storageRef, pdfFile);
        catalogUrl = await getDownloadURL(storageRef);
      } else {
        // URL登録の場合
        catalogUrl = url;
      }

      await addDoc(collection(firestore, "catalogs", "3DEUDyVarP8SeqGhAe83", "links"), {
        title, // ユーザーが入力したタイトルをそのまま使用
        url: catalogUrl, // PDFのURLまたは入力されたURLを保存
        image: imageUrl,
        order,
        open: true,
        last_update: new Date()
      });

      console.log('カタログが追加されました');
      setIsLoading(false); // ローディング終了
      setShowNotification(true);
    } catch (e) {
      console.error('Firestoreへの追加に失敗:', e);
      setIsLoading(false); // エラー時もローディング終了
      alert(`エラー: ${e.message}`);
    }
  };

  const handleNotificationOk = () => {
    setShowNotification(false);
    clearForm();
  };

  const clearForm = () => {
    setTitle('');
    setUrl('');
    setImage(null);
    setPdfFile(null);
    setOrder(order + 1);
  };

  const handleRegistrationTypeChange = (type) => {
    setRegistrationType(type);
    if (type === 'url') {
      setPdfFile(null);
    } else {
      setUrl('');
    }
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
        {/* ローディングオーバーレイ */}
        <div style={styles.loadingOverlay}>
          <div style={styles.loadingIndicator}></div>
          <span style={styles.loadingText}>アップロード中...</span>
        </div>
        
        <div style={styles.uploadTitle}>
          <p>カタログ登録</p>
        </div>

        <div style={styles.radioGroup}>
          <div style={styles.radioOption}>
            <input
              type="radio"
              id="urlRegistration"
              name="registrationType"
              checked={registrationType === 'url'}
              onChange={() => handleRegistrationTypeChange('url')}
              style={styles.radioInput}
              disabled={isLoading}
            />
            <label htmlFor="urlRegistration">URL登録</label>
          </div>
          <div style={styles.radioOption}>
            <input
              type="radio"
              id="fileRegistration"
              name="registrationType"
              checked={registrationType === 'file'}
              onChange={() => handleRegistrationTypeChange('file')}
              style={styles.radioInput}
              disabled={isLoading}
            />
            <label htmlFor="fileRegistration">ファイル登録</label>
          </div>
        </div>

        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="title">タイトル:</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={styles.inputField}
            disabled={isLoading}
          />
        </div>

        {registrationType === 'url' ? (
          <div style={styles.inputGroup}>
            <label style={styles.label} htmlFor="url">URL:</label>
            <input
              id="url"
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              style={styles.inputField}
              disabled={isLoading}
            />
          </div>
        ) : (
          <div style={styles.inputGroup}>
            <label style={styles.label} htmlFor="pdfFile">PDFファイル:</label>
            <input
              id="pdfFile"
              type="file"
              accept="application/pdf"
              onChange={handlePdfUpload}
              style={styles.inputField}
              disabled={isLoading}
            />
          </div>
        )}

        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="image">カタログ画像:</label>
          <input
            id="image"
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            style={styles.inputField}
            disabled={isLoading}
          />
        </div>

        <button
          type="button"
          onClick={uploadCatalog}
          style={styles.submitButton}
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? (
            <>
              <div style={styles.loadingIndicator}></div>
              カタログを追加中...
            </>
          ) : (
            isFormValid ? 'カタログを追加' : '全ての項目を入力してください'
          )}
        </button>
      </form>

      {showNotification && (
        <div style={styles.notification}>
          <p>カタログが追加されました。</p>
          <button onClick={handleNotificationOk} style={styles.okButton}>OK</button>
        </div>
      )}
    </div>
  );
}

export default CatalogUpload;