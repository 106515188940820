import React, { useState, useEffect } from 'react';
import { getFirestore, doc, updateDoc, Timestamp, getDoc } from 'firebase/firestore';

function CatalogDateSetter() {
  const [selectedDate, setSelectedDate] = useState('');
  const [currentStandardDate, setCurrentStandardDate] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  

  // フォームのバリデーションを行う
  useEffect(() => {
    setIsFormValid(selectedDate !== '');
  }, [selectedDate]);

  // 現在の基準日をFirestoreから取得する
  useEffect(() => {
    const fetchCurrentStandardDate = async () => {
      try {
        const db = getFirestore();
        const docRef = doc(db, "catalogs", "3DEUDyVarP8SeqGhAe83");
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.standard_date) {
            setCurrentStandardDate(data.standard_date.toDate());
          } else {
            setCurrentStandardDate(null);
          }
        } else {
          console.log("指定されたドキュメントは存在しません。");
          setCurrentStandardDate(null);
        }
      } catch (error) {
        console.error('Firestoreからの取得に失敗:', error);
        setFetchError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCurrentStandardDate();
  }, []);

  // 日付変更時のハンドラー
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  // Firestore の standard_date を更新する関数
  const updateStandardDate = async () => {
    if (!isFormValid) return;

    try {
      const db = getFirestore();
      const docRef = doc(db, "catalogs", "3DEUDyVarP8SeqGhAe83");
      
      // 選択した日付を Timestamp に変換
      const timestamp = Timestamp.fromDate(new Date(selectedDate));

      await updateDoc(docRef, {
        standard_date: timestamp,
        last_update: new Date()
      });

      console.log('基準日が更新されました');
      setShowNotification(true);
      // 更新後に現在の基準日を再取得
      setCurrentStandardDate(new Date(selectedDate));
      setSelectedDate('');
    } catch (error) {
      console.error('Firestoreの更新に失敗:', error);
      alert(`エラー: ${error.message}`);
    }
  };

  // 通知を閉じてフォームをリセットする
  const handleNotificationOk = () => {
    setShowNotification(false);
  };

  // スタイルの定義
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      width: '100%',
    },
    standDateTitle: {
        display: 'block',
        marginBottom: '8px',
        fontWeight: 'bold',
      },
  
    form: {
      width: '100%',
      maxWidth: '400px',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      textAlign: 'left',
    },
    inputGroup: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      marginBottom: '8px',
      fontWeight: 'bold',
    },
    inputField: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    submitButton: {
      padding: '10px 20px',
      backgroundColor: isFormValid ? '#110b41ff' : '#ccc',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: isFormValid ? 'pointer' : 'not-allowed',
      width: '100%',
    },
    notification: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      textAlign: 'center',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      marginTop: '20px',
    },
    okButton: {
      padding: '10px',
      backgroundColor: '#110b41ff',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginTop: '20px',
    },
    currentDate: {
      marginBottom: '20px',
      fontSize: '16px',
    },
    error: {
      color: 'red',
      marginBottom: '20px',
    },
    loading: {
      marginBottom: '20px',
      fontSize: '16px',
    },
  };

  // 日付をフォーマットする関数
  const formatDate = (date) => {
    if (!date) return '未設定';
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
        {/* 現在の基準日表示 */}
        <div style={styles.standDateTitle}>
          <p>基準日設定</p>
        </div>
        <div style={styles.currentDate}>
          <strong>現在の基準日:</strong> {isLoading ? '読み込み中...' : fetchError ? `エラー: ${fetchError}` : formatDate(currentStandardDate)}
        </div>

        {/* 日付入力フィールド */}
        <div style={styles.inputGroup}>
          <label style={styles.label} htmlFor="standardDate">新基準日:</label>
          <input
            id="standardDate"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
            style={styles.inputField}
          />
        </div>

        {/* 登録ボタン */}
        <button
          type="button"
          onClick={updateStandardDate}
          style={styles.submitButton}
          disabled={!isFormValid}
        >
          {isFormValid ? '登録' : '日付を選択してください'}
        </button>
      </form>

      {/* 通知表示 */}
      {showNotification && (
        <div style={styles.notification}>
          <p>基準日が更新されました。</p>
          <button onClick={handleNotificationOk} style={styles.okButton}>OK</button>
        </div>
      )}
    </div>
  );
}

export default CatalogDateSetter;
