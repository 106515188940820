import React from "react";
import styled from 'styled-components';

// 固定画像のパスを定義
const minoImage1 = require('../../images/mino/mino-3-1.jpg');
const minoImage2 = require('../../images/mino/mino-3-2.jpg');

// スタイルを定義
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Image = styled.img`
  width: 42%;
  height: auto;
  max-width: 500px;
  box-sizing: border-box;
  padding: 8px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const MinoBody3 = () => {
  return (
    <Container>
      <Title>対応力</Title>
      <p>
        物流業務において常に求められることは「正確さ」と「速さ」です。<br/><br/>
        当社では約30,000点を超える全商品の追跡調査を可能にするバーコード管理システムを構築。<br/><br/>
        受注から出荷までの全ての業務の「見える化」を実現しました。<br/><br/>
        より正確に、より確実に、より迅速に。<br/><br/>
        受注から出荷までの一貫システムで、高品質な物流サービスを提供いたします。<br/>
      </p>
      <ImageContainer>
        <Image src={minoImage1} alt="Mino3-1" />
        <Image src={minoImage2} alt="Mino3-2" />
      </ImageContainer>
    </Container>
  );
};

export default MinoBody3;
