import React, { useState, useEffect } from 'react';
import reqruitMember2TopImage from '../../images/reqruit/member2-1.jpg';
import reqruitMember2MidImage1 from '../../images/reqruit/member2-2.jpg';
import reqruitMember2MidImage2 from '../../images/reqruit/member2-3.jpg';
import reqruitMember2BotImage from '../../images/reqruit/member2-4.jpg';

const MessageFromMember2 = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px 0px',
      fontFamily: 'Arial, sans-serif',
    },
    section: {
      marginBottom: '40px',
    },
    heading: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '28px',
      backgroundColor: '#110b41ff',
      color: '#fff',
      padding: '20px',
      textAlign: 'center',
    },
    topSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'left',
      margin: '40px auto',
    },
    leftContent: {
      width: '100%',
      maxWidth: '720px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      textAlign: 'left',
    },
    rightContent: {
      width: '100%',
      marginBottom: '20px',
    },
    quote: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',
      lineHeight: '1.4',
      width: '100%',
      maxWidth: '720px',
    },
    info: {
      fontSize: '16px',
      lineHeight: '1.5',
      width: '80%',
      maxWidth: '320px',
    },
    image: {
      width: '100%',
      maxWidth: '720px',
      height: 'auto',
      borderRadius: '8px',
      margin: '0 auto',
    },
    middleSection: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'flex-start',
      marginBottom: '40px',
      gap: '32px',
    },
    middleSectionReverse: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row-reverse',
      alignItems: isMobile ? 'center' : 'flex-start',
      marginBottom: '40px',
      gap: '32px',
    },
    middleSectionCentered: {  // 新しく追加したスタイル
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '40px',
      gap: '32px',
      textAlign: 'center',
    },
    middleImageWrapper: {
      flex: '1',
      maxWidth: isMobile ? '100%' : '45%',
      marginBottom: isMobile ? '20px' : '0',
      order: isMobile ? 1 : 'unset',
    },
    middleImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '8px',
    },
    middleContentContainer: {
      flex: '1',
      maxWidth: isMobile ? '100%' : '55%',
      order: isMobile ? 2 : 'unset',
    },
    middleContentCentered: {
      fontSize: '16px',
      lineHeight: '1.6',
      textAlign: 'left',     // 'center' から 'left' に変更
      maxWidth: '720px',     // コンテンツの最大幅を設定
      margin: '0 auto',      // コンテナ自体は中央寄せのまま
    },
    middleTitleWrapper: {
      width: '100%',
      textAlign: 'center',
      marginBottom: '20px',
    },
    middleTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '20px',  // タイトルの下の余白を追加
    },
    middleContentWrapper: {
      width: '100%',
      maxWidth: '720px',  // コンテンツの最大幅を設定
      margin: '0 auto',   // 中央寄せ
    },
    middleContent: {
      fontSize: '16px',
      lineHeight: '1.6',
      textAlign: 'left',
    },
    bottomSection: {
      width: '100%',
    },
    fullWidthImage: {
      width: '100%',
      maxWidth: '640px',
      height: 'auto',
      margin: '0 auto',
    },
    divider: {
      borderTop: '1px solid #110b41ff',
      margin: '40px 0',
    },
    dividerTr: {
      margin: '24px 0',
    },
  };

  return (
    <div style={styles.container}>
      <section style={styles.topSection}>
        <div style={styles.rightContent}>
          <img
            src={reqruitMember2TopImage}
            alt="社員の写真"
            style={styles.image}
          />
        </div>
        <div style={styles.leftContent}>
          <p style={styles.quote}>
            お客様の期待に応え、感謝の言葉をいただけることが何よりのやりがいです。
          </p>
          <div style={styles.info}>
            <p>営業部</p>
            <p>2020年入社</p>
          </div>
        </div>
      </section>

      <div style={styles.divider}></div>

      <section style={styles.middleSection}>
        <div style={styles.middleImageWrapper}>
          <img
            src={reqruitMember2MidImage1}
            alt="営業スタイルの写真"
            style={styles.middleImage}
          />
        </div>
        <div style={styles.middleContentContainer}>
          <h3 style={styles.middleTitle}>豊栄/HOEIの営業スタイル</h3>
          <p style={styles.middleContent}>
            営業として、大手企業から個人店まで、業態・規模を問わずさまざまなお客様を担当しています。<br />
            当社の営業方針は対面営業を重視しており、午後はほぼ毎日外勤し、都内をはじめとしてお客様の本部や店舗へ訪問しています。<br />
            お客様と直接お話しすることで、電話やテキストでは見えなかった課題など深堀りし解決できることもあります。<br />
            また、なにげない雑談をしつつ、色々と任せていただける信頼関係を築けていることをうれしく思います。<br />
            仕入先や物流センターのメンバーの協力を得ながら、要望を形にできるよう全力で取り組んでいます！<br />
          </p>
        </div>
      </section>

      <div style={styles.divider}></div>

      <section style={styles.middleSectionReverse}>
        <div style={styles.middleImageWrapper}>
          <img
            src={reqruitMember2MidImage2}
            alt="豊栄を選んだ理由の写真"
            style={styles.middleImage}
          />
        </div>
        <div style={styles.middleContentContainer}>
          <h3 style={styles.middleTitle}>豊栄/HOEIを選んだ理由</h3>
          <p style={styles.middleContentCentered}>
            食に携わりながら、前職の飲食店店長の経験や知識を活かせる仕事がしたいと思い、当社を選びました。<br />
            メニュー開発の際、器ひとつで料理の印象がガラッと変わることに衝撃を受けました。それ以来、器への興味が深まりましたが、知識はほとんどゼロ。<br />
            営業職も未経験で不安はありましたが、優しく個性豊かな先輩方のもとで日々成長できています。<br />
            調査やレセプションにも参加し、器やメニューのトレンドを学べる環境です。<br />
          </p>
        </div>
      </section>

      <div style={styles.divider}></div>

      <section style={styles.middleSectionCentered}>
        <div style={styles.middleContentWrapper}>
          <h3 style={styles.middleTitle}>仕事で最もやりがいを感じる瞬間</h3>
          <p style={styles.middleContentCentered}>
            お客様に提案・採用いただいた商品が使われ、「豊栄/HOEIにお願いしてよかった」と感謝の言葉をいただけるときや、
            独立されたお客様から「またよろしく」とご連絡をいただいたときにやりがいを感じます。<br />
          </p>
        </div>
      </section>

      <section style={styles.bottomSection}>
        <img
          src={reqruitMember2BotImage}
          alt="全幅イメージ"
          style={styles.fullWidthImage}
        />
      </section>

    </div>
  );
};

export default MessageFromMember2;